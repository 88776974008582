import React from 'react';
import { Helmet } from "react-helmet";

import Search from '../components/Search';
import Footer from "../components/footer";

export default function Home( {data} ) {
  return (
    <>
      <Helmet>
        <title>Ce medicament?</title>

        <meta name="description" content="Singurul motor de cautare pentru medicamente" />
        <meta name="robots" content="index, follow" />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content="Ce medicament?" />
        <meta property="og:description" content="Singurul motor de cautare pentru medicamente" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cemedicament.ro/" />
        <meta property="og:image" content="https://cemedicament.ro/meds.jpg" />

        {/* Twitter */}
        <meta property="twitter:title" content="Ce medicament?" />
        <meta property="twitter:description" content="Singurul motor de cautare pentru medicamente" />
        <meta property="twitter:card" content="https://cemedicament.ro/meds.jpg" />
        <meta property="twitter:url" content="https://cemedicament.ro/" />
        <meta property="twitter:image" content="https://cemedicament.ro/meds.jpg" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/logo192.png" />

        <script data-ad-client="ca-pub-2333940847398247" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>

      <div id="root" className="uk-height-viewport uk-flex uk-flex-column">

        <div className="uk-section uk-section-default">
            <div className="uk-container uk-text-center">
                <a href="/">
                    <img src="logo.svg" width="100" height="" alt="" />
                </a>
                <h1 className="uk-heading-medium">
                    Ce medicament?
                </h1>
                <h3 className="">
                    Completează în casuța de căutare afecțiunea, simptomul predominant sau substanța activă.<br />Vei afla imediat
                    lista medicamentelor aprobate în România.
                </h3>
            </div>
        </div>

        <Search/>

      </div>

      <Footer/>
    </>
  )
}