import React, { Fragment, Component } from "react";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            searchInput: "",
            filteredSearchArray: [],
            loading: false
        };
    }

    handleChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };

    handleSearch = () => {
        let toreplace = ["de", "pe", "in", "si", "la"];
        let searchArray = this.state.searchInput.split(" ");
        let filteredSearchArray = searchArray.filter(sv => !toreplace.find(c => sv === c));
        this.setState({filteredSearchArray, loading: true});
        this.makeApiCall(filteredSearchArray.join(" "));
    };

    handleKeyPress = (event) => {
        if(this.state.searchInput) {
            if(event.key === 'Enter'){
                this.handleSearch();
            }
        }
    };

    makeApiCall = filteredSearchArray => {
        let searchUrl = `https://cemedicament.ro/api/query.php?keywords=${filteredSearchArray}`;
        fetch(searchUrl,
        {
                method: "GET",
                headers: {
                    'Content-type': 'application/json'
                },
            }
            )
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.setState({ data, loading: false });
            });
    };

    renderDescription(description) {
        let searchWords = this.state.filteredSearchArray;
        //find words
        return description.slice(0, 1000)
            .split(' ')
            .map(word => {
                let found = false;
                searchWords.map(sWord => {
                    if(word.indexOf(sWord) !== -1) {
                        found = true;
                    }
                });
                if(found) {
                    return "<span class='highlight'>"+ word + "</span>";
                } else {
                    return word;
                }
            }).join(' ');
    }

    render() {

        return (

            <Fragment>

                <div className="uk-section-default">
                    <div className="uk-container uk-flex uk-width-1-2@l uk-width-2-3@m uk-flex-column-s">
                        <div className="uk-inline uk-width-1-1">
                            <span uk-icon="icon: search" className="uk-form-icon"/>
                            <input
                                value={this.state.searchInput}
                                onChange={this.handleChange}
                                id="textsend"
                                className="uk-input uk-form-large"
                                type="text"
                                name="searchInput"
                                placeholder="Simptom, afecțiune ..."
                                onKeyPress={this.handleKeyPress}
                            />
                        </div>
                        <div className="uk-width-1-4@s button">
                            <button onClick={this.handleSearch}  id="button" className="uk-button uk-button-primary uk-button-large uk-width-expand" disabled={!this.state.searchInput}>
                            Caută
                            </button>
                        </div>
                    </div>
                    <p className="uk-text-meta uk-width-1-2@l uk-width-2-3@m uk-container">
                        *toate informațiile furnizate provin din baza de date Agenția Natională a Medicamentului și a Dispozitivelor Medicale din România
                    </p>
                </div>

                <div className="uk-container uk-text-center">
                    <a href="https://event.2performant.com/events/click?ad_type=banner&unique=f5aa093fd&aff_code=b2dea38f3&campaign_unique=6390e3cfb" target="_blank" rel="nofollow"><img src="https://img.2performant.com/system/paperclip/banner_pictures/pics/158285/original/158285.png" alt="drmax.ro/%20" title="drmax.ro/%20" border="0" height="330px" width="1140px"/></a>
                </div>

                {this.state.loading && <div className="lds-facebook">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>}

                {this.state.data ? (
                    <div className="uk-section uk-section-default">
                        <div className="uk-container uk-flex-middle">
                            <ul className="">
                                {this.state.data.map((medicament, id) => (
                                    <li key={id}>
                                        <p>{medicament.name} - Vezi pagina de produs <a target="_blank" href={'/medicament/' + medicament.id} rel="noopener noreferrer">aici</a></p>
                                        {/*<a className="uk-accordion-title" href="#">{medicament.name}</a>*/}
                                        {/*<div className="uk-accordion-content">*/}
                                            {/*<strong>Descriere</strong>*/}
                                            {/*<div dangerouslySetInnerHTML={{ __html: this.renderDescription(medicament.description) }} />*/}
                                            {/*<br/>*/}
                                            {/*<br/>*/}
                                            {/*<strong>Substanta activa:</strong>*/}
                                            {/*<div dangerouslySetInnerHTML={{ __html: this.renderDescription(medicament.substance) }} />*/}
                                            {/*<p>Vezi pagina produs <a target="_blank" href={'/medicament/' + medicament.id} rel="noopener noreferrer">aici</a></p>*/}
                                        {/*</div>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ) : (
                    <p></p>
                )}

            </Fragment>
        );
    }
}

export default Search;